
import React from 'react'
import { Box, Text, Heading } from 'theme-ui'

const Table = ({
    tableTitle,
    tableSubtitle,
    tableRow,
 }) => {

    const handleTable = () => tableRow.map(x =>
        <Box 
            sx={{
                display: 'flex',
                width: ['100%', '100%', 400],
                p: [3, 4, 3],
                px: [4,5,4],
                border: '0.3px solid rgba(255,255,255,0.1)',
            }}>
            <Box 
                sx={{
                    display: 'flex',
                    width: ['50%', '100%', '50%'],
                    fontSize: [4, 5, 3],
                    justifyContent: 'space-between',
                    alignItems: 'center',

                }}>
                {x.headline}
            </Box>
            <Box 
                sx={{
                  
                    width: '40%',
                    fontSize: [3, 5, 4],
                    textAlign: ['right','inherit',],
                }}>
                {x.value}
            </Box>


        </Box>
    )
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: ['column', 'column', 'row'],
                width: '100%',
                justifyContent: 'center',
                alignItems: ['center','center','center'],
                pt: [0, 0, 5],
                pb: 5,
            }}>
            {/* Naslov i text */}
            <Box className='splittext'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: ['100%', '100%', '40%'],
                    px: [2, 5, 0],
                    textAlign: ['center', 'center', 'inherit'],
                    mb: [4, 4, 0],


                }}>
                <Heading className='splittext'
                    sx={{
                        width: ['100%', '100%', '60%'],
                        fontSize: [6, 7, 7],
                        color: 'white',
                        pb: [4, 4, '15px'],
                    }}>
                    {tableTitle}
                </Heading>
                <Text className='splittext'
                    sx={{
                        display: 'flex',
                        width: ['auto', '90%', '90%'],
                        fontSize: [4, 5, 4],
                        color: 'white',
                    }}>
                    {tableSubtitle}
                </Text>
            </Box>

            <Box
                sx={{
                    width: ['100%,', '90%', 'auto'],
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'white',
                }}>

                <Heading className='splittext'
                    sx={{
                        display: 'flex',
                        alignItems: ['center', 'center', 'flex-end'],
                        justifyContent: ['center', 'center', 'flex-start'],
                        fontSize: [4, 5, 3],
                        color: 'white',
                        mb: [2, 3, 3],
                    }}>
                </Heading>

                {handleTable()}
            </Box>
        </Box>

    )
}
export default Table