import React, { useState, useEffect} from 'react'
import { Box, Heading, Text } from 'theme-ui'
import { gsap } from "gsap"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"

if (typeof window !== "undefined") {
    gsap.registerPlugin(DrawSVGPlugin);
  }

const CircularBenefits = ({ arrayData }) => {
    const [selectedBenefit, setSelectedBenefit] = useState(1)

    const handleSetSelectedBenefit = id => {
        setSelectedBenefit(id)
    }
    
    useEffect(() => {
        gsap.to(`.svgSelectedBoxClass svg path`, {drawSVG: "0%", duration: 0.25,})
        gsap.fromTo(`#svgSelectedBox-${selectedBenefit} svg path`, {drawSVG: "0%"}, {drawSVG: "100%", duration: 1,})
    },[selectedBenefit])

    const benefitArray = [
        {
            id: 1,
            headline: arrayData[0].title,
            description: arrayData[0].description,
            svg: <svg viewBox="0 0 209 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.641602 25C30.2586 10.3579 81.3112 1 109.5 1C137.689 1 168.074 5.23525 207.5 16.4999" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M207.72 16.2483C204.785 13.3136 201.965 10.1841 199.312 7" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M207.93 16.4581C204.94 17.4149 201.686 18.7618 199.312 20.8721C198.694 21.4212 197.777 22.0844 197 22.3434" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>,
            svgSelected: <svg viewBox="0 0 254 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.29785 27.7633C27.979 19.606 55.0424 12.5037 82.6051 7.96472C104.46 4.36572 127.006 5.00209 149.083 5.26491C163.517 5.43675 178.37 5.06631 192.749 6.36049C206.329 7.58269 219.663 11.5688 232.777 15.086C237.692 16.4042 242.53 18.0047 247.489 19.1552C248.969 19.4986 253.241 20.8956 251.95 20.0943C249.045 18.2913 245.477 17.4395 242.168 16.8076C233.655 15.1821 225.024 14.208 216.5 12.5818C175.053 4.67411 132.28 1 90.1176 1C81.9357 1 73.9147 1.08031 65.7802 2.05645C57.9557 2.9954 50.2925 4.92223 42.4601 5.77357C38.5676 6.19667 34.7375 6.83987 30.8784 7.49519C30.0468 7.6364 26.0195 8.04298 28.922 8.04298C39.2669 8.04298 49.3555 5.93008 59.7546 5.93008" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        },
        {
            id: 2,
            headline: arrayData[1].title,
            description: arrayData[1].description,
            svg: <svg viewBox="0 0 41 191" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.50293 1.2561C5.31761 8.8526 10.8827 16.0832 15.1872 23.9866C31.4663 53.876 38.6946 89.7332 40.1807 123.458C41.0422 143.006 37.1198 160.823 30.1733 178.926C28.9258 182.177 26.7536 185.94 26.7536 189.537" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.3012 189.537C24.1544 186.565 23.8682 182.362 22.9067 178.901" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.3013 189.763C31.3267 188.694 34.763 184.475 38.974 181.843" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>,
            svgSelected: <svg viewBox="0 0 268 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.39941 14.7425C23.7454 10.3029 46.0589 5.45733 68.7612 3.17606C83.5262 1.69237 98.4477 2.04313 113.267 1.84341C127.073 1.65734 140.002 3.24713 153.724 5.43905C187.771 10.8778 222.639 16.252 255.659 26.4597C258.777 27.4235 263.062 28.9106 266.044 30.6589C266.743 31.0685 266.155 31.1177 265.692 31.2623C263.77 31.863 261.277 31.4614 259.355 31.1869C250.212 29.8807 241.272 26.5848 232.426 24.0459C215.019 19.0502 197.641 13.8522 179.924 10.0405C153.449 4.34423 125.165 1.20557 98.2555 5.6905" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>                     
        },
        {
            id: 3,
            headline: arrayData[2].title,
            description: arrayData[2].description,
            svg: <svg viewBox="0 0 218 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M216.822 1.27686C210.342 9.37694 201.439 16.4201 192.935 22.197C172.713 35.9322 149.841 49.7636 125.648 54.8846C103.712 59.5282 79.1701 59.1334 57.2053 54.9852C39.2176 51.5882 19.1023 48.5776 2.29004 41.1055" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.38477 41.1054C5.6409 39.4228 18.7681 36.931 20.3939 32.0535" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.61084 40.6528C2.27988 44.2413 5.82247 46.7811 6.92887 50.2706C7.37526 51.6784 8.62612 53.4176 8.62612 54.9097" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>,
            svgSelected: <svg viewBox="0 0 347 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.00488 14.0568C5.24527 10.8141 9.55171 8.39518 14.5074 6.4129C17.333 5.28267 20.4521 3.60261 23.4337 2.94298C24.7227 2.65778 29.5131 2.25929 29.77 2.23894C40.0035 1.42837 50.2526 0.931102 60.5216 1.20802C71.6524 1.50818 83.2636 2.40091 94.039 5.45742C107.649 9.31789 120.3 15.881 134.119 19.2868C143.445 21.5852 154.125 23.3244 163.639 20.9966C171.938 18.9658 179.995 16.2029 188.406 14.5597C207.791 10.7724 227.488 10.5196 247.168 10.436C254.893 10.4032 262.957 9.83209 270.603 11.2658C277.516 12.5619 284.272 14.6432 291.07 16.4204C298.196 18.2832 304.474 19.488 311.739 19.488C322.972 19.488 334.214 19.2806 345.432 19.9406" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            
                       
        },
        {
            id: 4,
            headline: arrayData[3].title,
            description: arrayData[3].description,
            svg: <svg viewBox="0 0 80 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M79 103C56.0754 94.5665 38.8621 79.4489 27.0132 62.9605C17.9648 50.3693 7.07349 36.5428 8.06282 21.8026C8.5327 14.8017 12.4204 7.98182 12.4204 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.8037 1C11.3207 2.12345 0.290542 12.022 1.03611 12.7676" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13 1C15.2684 3.3393 17.042 5.94722 19.3364 8.24158" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>,
            svgSelected: <svg viewBox="0 0 194 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.42529 9.35017C13.7237 8.2391 25.8049 6.4705 37.9852 4.47216C59.5676 0.931307 82.0336 1.49968 103.813 1.656C125.272 1.81001 146.639 3.18521 167.856 6.43343C169.215 6.64155 193.25 10.5004 192.9 11.8143C192.487 13.3629 182.559 15.3055 181.61 15.4854C167.012 18.2527 150.851 17.5472 136.149 15.9631C120.092 14.2331 104.202 10.15 88.3242 7.28833C79.3562 5.672 70.3719 4.23723 61.3192 3.1898C58.4493 2.85775 54.9853 2.21976 52.0158 2.30975C49.2524 2.39349 56.9909 4.80649 59.6596 5.52823C74.3495 9.50096 90.1815 9.59202 105.272 10.4062C107.304 10.5159 116.334 10.9482 119.453 11.06C120.578 11.1003 123.836 11.2991 122.822 10.8085C120.137 9.50921 116.447 8.9345 113.619 8.3444C106.678 6.89551 99.6765 5.91691 92.5988 5.4528C83.0268 4.82513 72.0725 4.23518 62.9284 7.79122C60.3491 8.7943 58.6618 10.3659 61.3192 12.3926C66.5177 16.3576 73.8292 17.9219 79.9763 19.6091C86.5477 21.4127 93.2514 22.7957 99.6392 25.1911" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            
        },
        {
            id: 5,
            headline: arrayData[4].title,
            description: arrayData[4].description,
            svg: <svg viewBox="0 0 87 92" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.645508 90.9596C2.43384 85.0978 5.97658 79.3233 9.3706 74.2889C19.3401 59.5008 30.8753 46.9703 44.3213 35.2899C53.5715 27.2544 62.7651 19.1859 72.5081 11.7548C76.6852 8.56888 81.5532 5.9785 85.2814 2.25024" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M85.7341 2.25017C80.8824 2.25017 76.055 1.34497 71.251 1.34497" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M85.3945 2.47656C84.8319 5.18737 83.8104 7.92612 83.8104 10.7239C83.8104 11.1664 83.9236 11.5596 83.9236 11.9811C83.9236 12.6442 84.0627 13.5829 83.6973 14.131" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>,
            svgSelected: <svg viewBox="0 0 191 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.43457 8.81568C6.2862 6.14234 11.7398 5.45357 17.1749 4.7423C42.2725 1.45793 68.1407 1.37112 93.3624 2.7056C112.985 3.74385 132.755 6.67743 151.773 11.6821C164.426 15.0121 176.787 19.3777 189.263 23.2988" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            
            
        },
    ]

    const handleMobileBenefit = () => benefitArray.map(x =>
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: ['flex','flex','none'],
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: ['center','center','inherit'],
                px: [3,4,4],
                mb: [4],
              
            }}>
            <Heading
                sx={{
                    fontSize: [4,6,50], 
                    color: 'white',
                    pb: [3,3,0],
                
                }}>
                {x.headline}
            </Heading>
            <Text
            sx={{
                fontSize: [3,5,40], 
                color: 'white',
                pb: [3,4,0],
               
            }}>
                {x.description}
            </Text>

        </Box>
    )

    const handleBenefitContent = () => {
        let obj = benefitArray.find(x => x.id === selectedBenefit)
        console.log(obj)
        return (
            <>
                <Heading
                    sx={{
                        fontSize: 36,
                        color: 'white',
                        pb: 4,


                    }}>
                    {obj.headline}
                </Heading>

                <Text
                    sx={{
                        fontSize: 4,
                        fontWeight: 400,
                        color: 'white',

                    }}>
                    {obj.description}
                </Text>
            </>
        )
    }
    
    const handleHeadline = (id, heading, top, left, svg, svgTop, svgRight, svgWidth, svgHeight, svgSelected) => {
        return (
            <Box
                onClick={() => handleSetSelectedBenefit(id)}
                onMouseEnter={() => handleSetSelectedBenefit(id)}
                sx={{
                    display: ['none','none','flex'],
                    position: 'absolute',
                    height: ['auto','auto','auto'],
                    left: left,
                    top: top,
                    cursor: 'pointer',
                }}>

                <Heading
                    sx={{
                        transition: 'all 0.25s ease-in-out',
                        fontSize: 6,
                        color: id === selectedBenefit ? 'primaryPink' : 'white',

                    }}>
                    {heading}
                </Heading>

                <Box sx={{
                    position: 'absolute',
                    top: svgTop,
                    right: svgRight,
                    width: svgWidth,
                    height: svgHeight,
                }}>
                    {svg}
                </Box>
                <Box sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    top: '120%',
                    width: '100%',
                    // display: id == selectedBenefit ? 'block' : 'none',
                }}
                id={`svgSelectedBox-${id}`}
                className="svgSelectedBoxClass">
                    {svgSelected}
                </Box>

            </Box>
        )
    }
    return (
        <Box
            sx={{
                width: '100%',
                height: ['auto','auto','600px'],
                display: 'flex',
                flexDirection: ['column','column','inherit'],
                position: 'relative',
                mb: [0,0,4],
                alignItems: 'center',
                justifyContent: 'center',
                my: [0,0,7],
            }}>
            {handleMobileBenefit()}
            {handleHeadline(1, arrayData[0].triggerTitle, '-5%', '30%', benefitArray[0].svg, '-20px', '-20vw', '15vw', 'auto', benefitArray[0].svgSelected)}
            {handleHeadline(2, arrayData[1].triggerTitle, '10%', '70%', benefitArray[1].svg, '100px', '50%', '50px', '50px', benefitArray[1].svgSelected)}
            {handleHeadline(3, arrayData[2].triggerTitle, '90%', '70%', benefitArray[2].svg, '80%', '150%', '15vw', '50px', benefitArray[2].svgSelected)}
            {handleHeadline(4, arrayData[3].triggerTitle, '90%', '10%', benefitArray[3].svg, '-400%', '50%',
            '100px', 'auto', benefitArray[3].svgSelected)}
            {handleHeadline(5, arrayData[4].triggerTitle, '30%', '5%', benefitArray[4].svg, '-300%', '0%',
            '100px', '50px', benefitArray[4].svgSelected)}
            <Box
                sx={{
                    display: ['none','none','flex'],
                    flexDirection: 'column',
                    position: 'relative',
                    // bg: 'primaryPink',
                    maxWidth: 650,
                    height: 400,
                    color: 'white',
                    width: 650,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 100,
                    px: 80,
                    py: 5,

                    textAlign: 'center',


                }}>
                {handleBenefitContent()}
                {/* <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 1,
                        bottom: '-50px',
                    }}>
                    <svg width="80" height="80" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="73" cy="73" r="73" fill="white" />
                    </svg>

                </Box> */}
            </Box>
        </Box >

    )
}

export default CircularBenefits

